define('incus-vastaanota/pods/components/tooltip-popup/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isOpenPopup: true,
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      closeTooltip: function closeTooltip() {
        this.set("isOpenPopup", false);
        this.sendAction('action', false);
      }
    }
  });
});