define("incus-vastaanota/pods/forgotpw/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "position:relative;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "center_div registration");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
          morphs[2] = dom.createMorphAt(element8, 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element8, [5]), 0, 0);
          morphs[4] = dom.createElementMorph(element9);
          morphs[5] = dom.createMorphAt(element9, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordsValid", []], "passwords-valid"], [], []], " ", "center_div"]]], ["inline", "t", ["common.username"], [], ["loc", [null, [5, 15], [5, 38]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.username", ["loc", [null, [6, 20], [6, 34]]]]], [], []], "placeholder", ["subexpr", "t", ["common.username"], [], ["loc", [null, [6, 47], [6, 68]]]]], ["loc", [null, [6, 6], [6, 70]]]], ["inline", "t", ["passwordreset.description"], [], ["loc", [null, [7, 9], [7, 42]]]], ["element", "action", ["requestPWRenewal"], [], ["loc", [null, [10, 14], [10, 43]]]], ["inline", "t", ["passwordreset.send"], [], ["loc", [null, [10, 44], [10, 71]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 8
                },
                "end": {
                  "line": 33,
                  "column": 8
                }
              },
              "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "\n          position: absolute; \n          bottom: -10rem; \n          left: 66%;\n          padding: 17rem;");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "tooltip-popup", [], ["title", ["subexpr", "t", ["settings.userInfo.passwordRequimentTitle"], [], ["loc", [null, [28, 16], [28, 62]]]], "content", ["subexpr", "t", ["settings.userInfo.passwordRequiment"], [], ["loc", [null, [29, 18], [29, 59]]]], "action", "popup"], ["loc", [null, [27, 10], [31, 12]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 2
              },
              "end": {
                "line": 61,
                "column": 4
              }
            },
            "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1, "autocomplete", "off");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "position:relative;");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "style", "cursor: pointer;");
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("i");
            dom.setAttribute(el5, "class", "fa fa-info-circle");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n        ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "valid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-check");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "invalid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-times");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "password-strength-meter");
            var el3 = dom.createTextNode("\n\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "style", "position:relative;");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "valid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-check");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "invalid-feedback feedback-icon");
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-times");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "center_div registration");
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "registration-button registration-button-button");
            dom.setAttribute(el3, "type", "submit");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element3, [2]);
            var element5 = dom.childAt(element1, [3, 1]);
            var element6 = dom.childAt(element1, [5]);
            var element7 = dom.childAt(element1, [7, 1]);
            var morphs = new Array(15);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createAttrMorph(element2, 'name');
            morphs[2] = dom.createAttrMorph(element2, 'autocomplete');
            morphs[3] = dom.createMorphAt(element3, 0, 0);
            morphs[4] = dom.createElementMorph(element4);
            morphs[5] = dom.createMorphAt(element3, 4, 4);
            morphs[6] = dom.createMorphAt(element2, 3, 3);
            morphs[7] = dom.createAttrMorph(element5, 'class');
            morphs[8] = dom.createAttrMorph(element6, 'class');
            morphs[9] = dom.createAttrMorph(element6, 'name');
            morphs[10] = dom.createAttrMorph(element6, 'autocomplete');
            morphs[11] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
            morphs[12] = dom.createMorphAt(element6, 3, 3);
            morphs[13] = dom.createElementMorph(element7);
            morphs[14] = dom.createMorphAt(element7, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordsValid", []], "passwords-valid"], [], []], " ", "center_div"]]], ["attribute", "name", ["get", "new-password", []]], ["attribute", "autocomplete", ["get", "off", []]], ["inline", "t", ["settings.userInfo.newPass"], [], ["loc", [null, [17, 13], [17, 46]]]], ["element", "action", ["popup"], ["bubbles", false], ["loc", [null, [18, 14], [18, 46]]]], ["block", "if", [["get", "notifyPopup", ["loc", [null, [21, 14], [21, 25]]]]], [], 0, null, ["loc", [null, [21, 8], [33, 15]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "model.newPass", ["loc", [null, [35, 36], [35, 49]]]]], [], []], "placeholder", ["subexpr", "t", ["settings.userInfo.newPass"], [], ["loc", [null, [35, 62], [35, 93]]]]], ["loc", [null, [35, 6], [35, 95]]]], ["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordStrengthClass", []], "password-strength-class"], [], []], " ", "password-strength-meter-bar"]]], ["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordsValid", []], "passwords-valid"], [], []], " ", "center_div"]]], ["attribute", "name", ["get", "repeat-new-password", []]], ["attribute", "autocomplete", ["get", "off", []]], ["inline", "t", ["settings.userInfo.repeatPass"], [], ["loc", [null, [48, 15], [48, 51]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "model.repeatPass", ["loc", [null, [49, 36], [49, 52]]]]], [], []], "placeholder", ["subexpr", "t", ["settings.userInfo.repeatPass"], [], ["loc", [null, [49, 65], [49, 99]]]]], ["loc", [null, [49, 6], [49, 101]]]], ["element", "action", ["changePassword"], [], ["loc", [null, [58, 69], [58, 96]]]], ["inline", "t", ["settings.userInfo.changePassword"], [], ["loc", [null, [58, 111], [58, 153]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 4
              },
              "end": {
                "line": 63,
                "column": 89
              }
            },
            "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "invalid-text");
            dom.setAttribute(el1, "style", "color:red");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "new-link");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["passwordreset.invalidlink"], [], ["loc", [null, [62, 46], [62, 80]]]], ["element", "action", ["resetState"], [], ["loc", [null, [63, 26], [63, 49]]]], ["inline", "t", ["passwordreset.here"], [], ["loc", [null, [63, 50], [63, 76]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 2
            },
            "end": {
              "line": 64,
              "column": 2
            }
          },
          "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "model.validRequest", ["loc", [null, [14, 8], [14, 26]]]]], [], 0, 1, ["loc", [null, [14, 2], [63, 96]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 67,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/forgotpw/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "forgotpw-wrapper");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pwForm");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element10, 1, 1);
        morphs[1] = dom.createMorphAt(element10, 2, 2);
        return morphs;
      },
      statements: [["block", "if", [["get", "model.requesting", ["loc", [null, [3, 8], [3, 24]]]]], [], 0, null, ["loc", [null, [3, 2], [12, 9]]]], ["block", "unless", [["get", "model.requesting", ["loc", [null, [13, 12], [13, 28]]]]], [], 1, null, ["loc", [null, [13, 2], [64, 13]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});