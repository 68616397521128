define('incus-vastaanota/pods/tools/user/route', ['exports', 'ember', 'incus-vastaanota/utils/email', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/countrycodes', 'incus-vastaanota/utils/validate-utils'], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsCountrycodes, _incusVastaanotaUtilsValidateUtils) {

  var view = _ember['default'].View.create({
    templateName: 'testeri',
    value: 'asdi'
  });
  exports['default'] = _ember['default'].Route.extend({
    apixclient: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    model: function model() {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var url = this.get("countryselector.url");
      var contractInitialState = url === "laskumappi" ? {
        categories: {
          send: false,
          receive: false,
          archive: false
        },
        send: {
          duplicateCheck: false,
          accounting: false,
          priority: false,
          consumerInvoicing: false,
          edi: false,
          debtCollect: false,
          factoringCopy: false
        },
        receive: {
          arrivalNotification: false,
          expiryNotification: false,
          scanning: false,
          emailScan: false,
          edi: false
        }
      } : {
        categories: {
          send: false,
          receive: false,
          archive: false
        },
        receive: {
          arrivalNotification: false,
          expiryNotification: false
        }
      };

      var categories = url === "laskumappi" ? [{
        name: "categories.send",
        apiName: "Send",
        field: false
      }, {
        name: "send.duplicateCheck",
        apiName: "Dupcheck",
        field: false
      }, {
        name: "send.accounting",
        apiName: "Accountingcopy",
        field: false
      }, {
        name: "send.priority",
        apiName: "Priority",
        field: false
      }, {
        name: "send.consumerInvoicing",
        apiName: "B2Cinvoicing",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "send.edi",
        apiName: "SendEDI",
        field: false,
        properties: {
          isAdvert: true
        }
      },
      // should the defaults be set to true by an observer?
      {
        name: "send.debtCollect",
        apiName: "DebtCollect",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "send.factoringCopy",
        apiName: "FactoringCopy",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "categories.receive",
        apiName: "Receive",
        field: false
      }, {
        name: "receive.arrivalNotification",
        apiName: "InvoiceArrivalNotify",
        field: false,
        parameter: null,
        properties: {
          hasEditableParameter: true
        }
      }, {
        name: "receive.expiryNotification",
        apiName: "InvoicePaymentExpiryNotify",
        field: false,
        parameter: null,
        properties: {
          hasEditableParameter: true
        }
      }, {
        name: "receive.scanning",
        apiName: "Scanning",
        field: false,
        parameter: 'OC'
      }, {
        name: "receive.emailScan",
        apiName: "EMailScan",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "receive.edi",
        apiName: "ReceiveEDI",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "categories.archive",
        apiName: "Archive",
        field: false
      }] : [{
        name: "categories.send",
        apiName: "Send",
        field: false
      }, {
        name: "categories.receive",
        apiName: "Receive",
        field: false
      }, {
        name: "receive.arrivalNotification",
        apiName: "InvoiceArrivalNotify",
        field: false,
        parameter: null,
        properties: {
          hasEditableParameter: true
        }
      }, {
        name: "receive.expiryNotification",
        apiName: "InvoicePaymentExpiryNotify",
        field: false,
        parameter: null,
        properties: {
          hasEditableParameter: true
        } }];

      var model = _ember['default'].Object.extend({
        newPass: '',
        oldPass: '',
        repeatPass: '',
        queryDone: false,
        name: '',
        additionalName: '',
        codes: (0, _incusVastaanotaUtilsCountrycodes['default'])(this.get("i18n.locale")),
        build: _incusVastaanotaConfigEnvironment['default'].build,
        selectedCountry: "FI",
        contactPerson: '',
        phone: '',
        www: '',
        i18n: this.get("i18n"),
        selectedAddress: "Official",
        addressTypes: [{ value: "Official", tstring: "settings.userInfo.officialAddress" }, { value: "Billing", tstring: "settings.userInfo.billingAddress" }, { value: "Visiting", tstring: "settings.userInfo.visitingAddress" }],
        addresses: [{
          AddressType: "Official",
          Country: "",
          PostalCode: "",
          PostalOffice: "",
          Street1: "",
          Street2: ""
        }, {
          AddressType: "Visiting",
          Country: "",
          PostalCode: "",
          PostalOffice: "",
          Street1: "",
          Street2: ""
        }, {
          AddressType: "Billing",
          Country: "",
          PostalCode: "",
          PostalOffice: "",
          Street1: "",
          Street2: ""
        }],
        validations: {
          newPasswords: null,
          Official: {
            Country: '',
            PostalCode: '',
            PostalOffice: '',
            Street1: '',
            Street2: ''
          },
          Visiting: {
            Country: '',
            PostalCode: '',
            PostalOffice: '',
            Street1: '',
            Street2: ''
          },
          Billing: {
            Country: '',
            PostalCode: '',
            PostalOffice: '',
            Street1: '',
            Street2: ''
          }
        },
        addressesObserver: _ember['default'].observer("addresses.@each.Country", "addresses.@each.PostalCode", "addresses.@each.PostalOffice", "addresses.@each.Street1", "addresses.@each.Street2", function () {
          var _this = this;

          var addresses = this.get("addresses");
          /*jshint loopfunc: true */

          var _loop = function (i) {
            var empty = true;
            var validations = _this.get("validations");
            Object.keys(addresses[i]).forEach(function (key) {
              if (addresses[i][key] !== '' && key !== "AddressType") {
                empty = false;
              }
            });
            if (!empty) {
              Object.keys(addresses[i]).forEach(function (key) {
                if (addresses[i][key] !== '' && key !== "AddressType") {
                  _ember['default'].set(_this.get("validations" + "." + addresses[i].AddressType), key, 'is-valid');
                } else {
                  _ember['default'].set(_this.get("validations" + "." + addresses[i].AddressType), key, 'is-invalid');
                }
              });
            } else {
              Object.keys(addresses[i]).forEach(function (key) {
                _ember['default'].set(_this.get("validations" + "." + addresses[i].AddressType), key, '');
              });
            }
          };

          for (var i = 0; addresses.length > i; i++) {
            _loop(i);
          }
        }),
        /*         categoriesObs: Ember.observer("categories.@each.field", function() {
                  console.log(this.get("categories"))
                }), */
        contractInitialState: contractInitialState,
        categories: categories,

        newPasswordsObserver: _ember['default'].observer("newPass", "repeatPass", function () {
          var newPass = this.get("newPass");
          var repeatPass = this.get("repeatPass");

          if (newPass && repeatPass) {
            if (newPass === repeatPass && _incusVastaanotaUtilsValidateUtils['default'].validatePassword(newPass)) {
              this.set("validations.newPasswords", "is-valid");
            } else {
              this.set("validations.newPasswords", "is-invalid");
            }
          }
        })
      }).create();

      return model;
    },
    afterModel: function afterModel(model) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var addressData = this.get("session").get("user");
      addressData = addressData.slice(1, addressData.length);
      addressData.forEach(function (element) {
        var addressIndex = model.get("addresses").findIndex(function (addr) {
          return addr.AddressType === element.AddressType;
        });
        var addressToUpdate = model.get("addresses").objectAt(addressIndex);
        Object.keys(element).forEach(function (key) {
          _ember['default'].set(addressToUpdate, key, element[key]);
        });
      });

      model.set("name", userData.Name);
      model.set("additionalName", userData.AdditionalName);
      model.set("city", addressData.City);
      model.set("zip", addressData.PostCode);
      model.set("contactPerson", userData.ContactPerson);
      model.set("www", userData.Www);
      model.set("phone", userData.Phonenumber);

      apixclient.listContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID).then(function (data) {
        var categories = model.get("categories");
        var initialState = model.get("contractInitialState");

        var _loop2 = function (i) {
          var contract = data.data.find(function (contract) {
            return contract.ContractType === categories[i].apiName;
          });
          if (contract) {
            _ember['default'].set(categories.objectAt(i), 'field', true);
            _ember['default'].set(initialState, categories.objectAt(i).name, true);
            if (contract.ContractType === "InvoiceArrivalNotify" || contract.ContractType === "InvoicePaymentExpiryNotify") {
              _ember['default'].set(categories.objectAt(i), 'parameter', contract.Parameters);
            }
          }
        };

        for (var i = 0; i < categories.length; i++) {
          _loop2(i);
        }
        model.set("contractInitialState", initialState);
        console.log(model.get("contractInitialState"));
      })['catch'](function (error) {
        console.log(error);
      });
    }

  });
});